// Filename - pages/about.js
import { React, useEffect } from "react";
import { useNavigate } from "react-router";
import "./login.css";

const Logout = () => {
  const navigate = useNavigate();
  const logout = async () => {
    try {
      await fetch("/auth/logout");
    } catch (error) {
      console.error(error);
    } finally {
      navigate("/");
      window.location.reload();
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <div class="page-body flex-grow-1">
      <div class="container-fluid">
        <div class="row row-deck row-cards">
          <div class="container-tight">
            <div class="text-center mb-4">
              <a href="." class="navbar-brand navbar-brand-autodark">
                <img
                  src="https://preview.tabler.io/static/logo.svg"
                  height="36"
                  alt=""
                />
              </a>
            </div>

            <p>Logging out...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
