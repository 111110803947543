import React, { useEffect, useState } from "react";
import ImageCard from "../../components/ImageCard/index";
import "./tab.css";

const TabItem = (props) => {
  const tab = props.tab;

  const handleRemove = (event) => {
    event.preventDefault();

    fetch("/api/v1/tab/" + tab._id, {
      method: "DELETE",
    })
      .then((r) => r.json())
      .then((r) => {
        if ("ok" === r.status) {
          return true;
        } else {
          return false;
        }
      });
    const currentNode = event.currentTarget.parentNode;
    currentNode.parentNode.removeChild(currentNode);
  };

  return (
    <li className="list-group-item d-flex justify-content-between align-items-center tab-item">
      <img
        src={tab.icon}
        className="favicon"
        style={{ marginRight: 10 }}
        alt=""
      />
      <a className="tab-title" href={tab.url} target="_blank" rel="noreferrer">
        {tab.title}
      </a>
      <button className="btn btn-sm btn-danger" onClick={handleRemove}>
        Remove
      </button>
    </li>
  );
};

const Tab = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getTabs = async () => {
    try {
      const response = await fetch("/api/v1/tab/all");
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTabs();
  }, []);

  const handleImageDeletion = (image_id) => {
    fetch("/api/v1/art/" + image_id, {
      method: "DELETE",
    })
      .then((r) => r.json())
      .then((r) => {
        console.log("deleting image from parent", image_id);
        if ("ok" === r.status) {
          return true;
        } else {
          return false;
        }
      });
  };

  return (
    <div className="page-body">
      <div className="container-xl">
        <div className="row row-cards" id="tabs_management">
          <div className="col-lg-6">
            <div className="container">
              <button id="sortButton" className="sort-button btn btn-primary">
                Sort Tabs
              </button>
              <h1>Open Tabs</h1>
              <ul id="tabsList" className="list-group">
                {/* Open tabs will be listed here */}
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="container">
              <h2>Favorite Tabs</h2>
              <ul id="favoriteTabsList" className="list-group">
                {data.map((item) => (
                  <TabItem tab={item} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
