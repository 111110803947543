// Filename - pages/about.js
import { React, useState } from "react";
import { useNavigate } from "react-router";
import "./login.css";

const Login = () => {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Log in a user using email and password
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("username", username);
    data.append("password", password);

    fetch("../auth/token", {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
      },
      body: data,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r["access_token"]) {
          //   localStorage.setItem(
          //     "user",
          //     JSON.stringify({ email, token: r.token })
          //   );
          //   props.setLoggedIn(true);
          //   props.setEmail(email);
          navigate("/");
          window.location.reload();
        } else {
          const toastLiveExample = document.getElementById("liveToast");
          toastLiveExample.classList.add("show");
        }
      });
  };

  return (
    <div class="page-body flex-grow-1">
      <div class="container-fluid">
        <div class="row row-deck row-cards">
          <div class="container-tight">
            <div class="text-center mb-4">
              <a href="." class="navbar-brand navbar-brand-autodark">
                <img
                  src="https://preview.tabler.io/static/logo.svg"
                  height="36"
                  alt=""
                />
              </a>
            </div>

            <form class="card card-md">
              <div class="card-body">
                <h2 class="card-title text-center mb-4">
                  Login to your account
                </h2>

                <div class="mb-3">
                  <label class="form-label">Username</label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    class="form-control "
                    placeholder="Username"
                    autocomplete="off"
                    onChange={(ev) => setUsername(ev.target.value)}
                  />
                </div>
                <div class="mb-2">
                  <label class="form-label">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    class="form-control "
                    placeholder="Password"
                    autocomplete="off"
                    onChange={(ev) => setPassword(ev.target.value)}
                  />
                </div>
                <div class="mb-2">
                  <label class="form-check">
                    <input
                      name="remember_me"
                      type="checkbox"
                      class="form-check-input"
                    />
                    <span class="form-check-label">Remember me</span>
                  </label>
                </div>
                <div class="form-footer">
                  <button onClick={handleSubmit} class="btn btn-primary w-100">
                    Sign in
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="toast-container position-fixed bottom-0 end-0 p-3">
            <div
              id="liveToast"
              class="toast"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-bs-autohide="false"
              data-bs-toggle="toast"
            >
              <div class="toast-header">
                <strong class="me-auto">Login error</strong>
                <small>1 min ago</small>
                <button
                  type="button"
                  class="ms-2 btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                ></button>
              </div>
              <div class="toast-body">Username or password incorrect!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
