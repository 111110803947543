import loadjs from "loadjs";
import { React, useEffect } from "react";
import { Outlet } from "react-router";
import "../../assets/fontawesome.min.css";
import "../../assets/tabler.min.css";

function AuthLayout() {
  useEffect(() => {
    loadjs(
      "https://cdn.jsdelivr.net/npm/@tabler/core@1.0.0-beta17/dist/js/tabler.min.js"
    );
  }, []);

  return (
    <div class="page">
      <header class="navbar navbar-expand-md navbar-light d-none d-lg-flex d-print-none">
        <div class="container-xl">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
            aria-controls="navbar-menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="navbar-nav flex-row order-md-last">
            <div class="nav-item dropdown">
              <button
                type="button"
                class="btn dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-language"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 5h7"></path>
                  <path d="M9 3v2c0 4.418 -2.239 8 -5 8"></path>
                  <path d="M5 9c-.003 2.144 2.952 3.908 6.7 4"></path>
                  <path d="M12 20l4 -9l4 9"></path>
                  <path d="M19.1 18h-6.2"></path>
                </svg>
                English
              </button>
              <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <a
                  href="#"
                  className="dropdown-item language-switcher-item"
                  data-lang="en"
                  data-cookie-name="language"
                >
                  English
                </a>

                <a
                  href="#"
                  className="dropdown-item language-switcher-item"
                  data-lang="tr"
                  data-cookie-name="language"
                >
                  Türkçe
                </a>
              </div>
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbar-menu"></div>
        </div>
      </header>

      <div class="page-wrapper">
        <div class="container-fluid">
          <div class="page-header d-print-none"></div>
        </div>
        <Outlet></Outlet>
      </div>
    </div>
  );
}

export default AuthLayout;
