import React from "react";
import "./index.css";

function ImageCard(props) {
  const image = props.image;
  const deletionCallback = props.deletionCallback;

  const handleDelete = async (event) => {
    event.preventDefault();
    deletionCallback(image._id);
    const root = event.currentTarget.parentNode.parentNode.parentNode;
    root.style.display = "None";
  };

  return (
    <div className="col-md-6 col-lg-3">
      <div className="card">
        <div
          className="img-responsive img-responsive-21x9 card-img-top"
          style={{ backgroundImage: `url(/api/v1/image/${image.image_url})` }}
        />
        <div className="card-body">
          <h3 className="card-title">created at: {image.created_at}</h3>
          <p className="text-muted">{image.prompt}</p>
        </div>
        <div className="card-footer">
          <a
            href="#"
            className="btn btn-primary delete-btn"
            id={image._id}
            onClick={handleDelete}
          >
            Delete
          </a>
        </div>
      </div>
    </div>
  );
}

export default ImageCard;
