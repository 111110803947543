// Filename - pages/contact.js

import React from "react";

const Contact = () => {
  return (
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards" id="sdImageResult">
          contact
        </div>
      </div>
    </div>
  );
};

export default Contact;
