import React, { useEffect, useState } from "react";
import ImageCard from "../../components/ImageCard/index";

const Home = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getImages = async () => {
    try {
      const response = await fetch("/api/v1/art/all");
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  const handleImageDeletion = (image_id) => {
    fetch("/api/v1/art/" + image_id, {
      method: "DELETE",
    })
      .then((r) => r.json())
      .then((r) => {
        console.log("deleting image from parent", image_id);
        if ("ok" === r.status) {
          return true;
        } else {
          return false;
        }
      });
  };

  return (
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards" id="sdImageResult">
          {data.map((item, index) => (
            <ImageCard
              image={item}
              deletionCallback={handleImageDeletion}
            ></ImageCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
