import loadjs from "loadjs";
import { React, useEffect } from "react";
import { Outlet } from "react-router";
import "../../assets/fontawesome.min.css";
import "../../assets/tabler.min.css";
import Header from "./Header";

function Layout() {
  useEffect(() => {
    loadjs(
      "https://cdn.jsdelivr.net/npm/@tabler/core@1.0.0-beta17/dist/js/tabler.min.js"
    );
  }, []);

  return (
    <div class="page">
      <Header>aa</Header>
      <div class="page-wrapper">
        <Outlet></Outlet>
      </div>
    </div>
  );
}

export default Layout;
