import React from "react";
import "./video.css";

const VideoPage = () => {
  return (
    <>
      <div className="comparison-group">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Prompt:</h3>
            <div className="text-secondary">
              This is the video prompt This is the video prompt This is the
              video prompt This is the video prompt This is the video prompt
              This is the video prompt This is the video prompt
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <video width="100%" controls autoPlay muted>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="col-md-6">
                <video width="100%" controls autoPlay muted>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Comparison Group 2 */}
      {/* Repeat the above structure for Group 2 */}
      {/* Comparison Group 1 */}
      <div className="comparison-group">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Prompt:</h3>
            <div className="text-secondary">
              This is the video prompt This is the video prompt This is the
              video prompt This is the video prompt This is the video prompt
              This is the video prompt This is the video prompt
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <video width="100%" controls autoPlay muted>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="col-md-6">
                <video width="100%" controls autoPlay muted>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Comparison Group 1 */}
      <div className="comparison-group">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Prompt:</h3>
            <div className="text-secondary">
              This is the video prompt This is the video prompt This is the
              video prompt This is the video prompt This is the video prompt
              This is the video prompt This is the video prompt
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <video width="100%" controls autoPlay muted>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="col-md-6">
                <video width="100%" controls autoPlay muted>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPage;
